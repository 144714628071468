import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import InfoBlocks from "../components/Index/InfoBlocks"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import BannerArea from "../components/Common/BannerArea"
import SEO from "../components/App/SEO"
import IntroHostingPlansWordpress from "../components/HostingPlans/IntroHostingPlansWordpress"
import PromoInfoRowWordpress from "../components/PromoInfoRow/PromoInfoRowWordpress"
import CustomerSupportWordpress from "../components/Index/CustomerSupportWordpress"
import SiteBenefitsWp from "../components/SiteBenefits/SiteBenefitsWp"
import FreeHosting from "../components/Common/FreeHosting";
import IntroHostingBgWhite from "../components/HostingPlans/IntroHostingBgWhite";

const GazduireWordpressSsd = () => {
    return (
        <Layout>
            <SEO title="Găzduire Optimizată pentru WordPress - Sitebunker.ro"
                 description="Ești în căutare de găzduire WordPress pentru site-ul tău? Sitebunker.ro îți oferă inclusiv servicii de hosting WooCommerce eficiente la costuri accesibile."
            />
            <Navbar/>

            <IntroHostingBgWhite
                title={'Hosting WordPress Eficient, adaptat nevoilor tale'}
                subtitle={'Vezi care este pachetul de găzduire potrivit și beneficiază de suport specializat WordPress și o temă premium la alegere.'}
            />
            <HostingPlanBlock category="Wordpress"
                              showPromos={true}/>
            <BannerArea
                pageTitle="Găzduire WordPress Premium - LiteSpeed WP Cache inclus"
                pageSubtitl=""
                pageDescription="Ideale pentru site-uri de prezentare, de știri, bloguri și magazine online WooCommerce, pachetele de găzduire WordPress disponibile la SiteBunker vin la pachet cu multiple beneficii și resurse premium de procesare, stocare și memorii RAM."
                pageDescriptionTwo="Verifică ce găzduire WordPress se potrivește succesului tău în online și nu uita că poți testa beneficiile SiteBunker GRATUIT timp de 30 zile."
                svgIllustration="wordpress"
            />
            <FreeHosting title='Alege-ți pachetul de hosting și ai domeniu .ro sau .eu gratuit'
                         text1='Bucură-te de servicii premium pentru site-ul tău WordPress, fie el de prezentare, blog sau magazin online! '
                         text2='La orice pachet de hosting ales (cu excepția pachetului Start) ai un domeniu .ro sau .eu gratuit pentru 1 an.'/>
            <PromoInfoRowWordpress/>
            <InfoBlocks/>
            <ClientFeedback
                title={"Află ce spun clienții despre serviciile de "}
                bold={"hosting SiteBunker!"}
            />
            <SiteBenefitsWp/>
            <CustomerSupportWordpress/>
            <Footer/>
        </Layout>
    )
}

export default GazduireWordpressSsd
