import React from "react"
import { Link } from "gatsby"
import techImg from "../../assets/images/new-imgs/technical-assistance.png"

const CustomerSupportWordpress = () => {
  return (
    <section id="customer-support">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 position-relative">
            <div className="svg-img">
              <img src={techImg} alt="Gazduire Web" loading="lazy"/>
            </div>
          </div>
          <div className="col-md-6 info-text">
            <h2 className="mb-5 h3-title">Support Ticketing Rapid <span>24/7</span></h2>
            <p>Suntem prompți în orice situație.</p>
            <p className="mb-5 "> Poți intra în legătură cu echipa suport SiteBunker ușor și facil, în doar câteva click-uri. Accesează secțiunea de suport din cadrul contului tău și contactează-ne indiferent de provocarea tehnică pe care o întâmpini.</p>
            <Link to="/contact/" className="view-more-button">Testează-ne disponibiliatea</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CustomerSupportWordpress;
