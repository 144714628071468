import React from "react"
import antivirusIcon from "../../assets/images/sistem-antivirus.png"
import notifIcon from "../../assets/images/sistem-notificari.png"
import servIcon from "../../assets/images/servicii-web.png"
import { Link } from "gatsby"

const SiteBenefitsWp = () => {
  return (
    <section id="site-benefits">
      <div className="container">
        <div className="title-row">
          <h2 className="h3-title text-center mb-4">Găzduire WordPress eficientă</h2>
        </div>
        <div className="row">
          <div className="col-md-4 benefit-col">
            <img src={servIcon} alt="" loading="lazy" />
            <h3 className="benefits-title">ABONAMENTE ACCESIBILE</h3>
            <p>Vrei ca resursele tale de găzduire WordPress să fie dedicate în funcție de nevoile site-ului tău de
              prezentare sau a magazinului tău online care abia se lansează? Descoperă pachetele de găzduire WordPress
              disponibile la abonamente accesibile.</p>
          </div>
          <div className="col-md-4 benefit-col">
            <img src={notifIcon} alt="" loading="lazy" />
            <h3 className="benefits-title">BACKUP ZILNIC AUTOMAT</h3>
            <p>Backup-ul datelor tale se realizează zilnic într-un mod automat și în locații externe, astfel încât tu să
              te poți concentra liniștit pe ce contează pentru succesul tău în online. Alege pachetul de găzduire
              WordPress sau contactează echipa suport <Link to={"/contact"}>aici.</Link>
            </p>
          </div>
          <div className="col-md-4 benefit-col">
            <img src={antivirusIcon} alt="" loading="lazy" />
            <h3 className="benefits-title">TRANSPARENȚĂ ȘI PROMPTITUDINE</h3>
            <p>Vei fi primul care va afla despre orice îți poate influența site-ul. Echipa SiteBunker monitorizează 24/7
              starea site-ului tău și îți pune la dispoziție
              un sistem de notificări în real-time unde poți verifica statusul serviciilor de găzduire <Link to={"/status."}> WordPress alese.</Link></p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SiteBenefitsWp
