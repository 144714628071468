import React from "react"
import SvgProvide from "../../assets/svgComponents/host-provider.svg"
import aboutUs from "../../assets/images/new-imgs/about-us-2.png"


const PromoInfoRowWordpress = () => {
  return (
    <section>
      <section id="promo-info-row">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 info-text">
              <h2 className="h3-title mb-3">Hosting WordPress eficient</h2>
              <p>Website-ul tău de prezentare sau magazinul online WooCommerce sunt complete alături de un Hosting
                Wordpress eficient.
                Fii pregătit pentru a scala odată cu succesul site-ului tău cu pachetele de găzduire WordPress oferite
                de SiteBunker.</p>

              <h2 className="h3-title mb-3 mt-5">Securitate asigurată de Imunify360</h2>
              <p>Alături de resursele premium de procesare, stocare și memorii RAM necesare nevoilor tale de hosting
                WordPress, la SiteBunker ai asigurată inclusiv protecția DDoS prin soluția Arbor implementată, inclusiv
                migrarea gratuită. Contactează-ne!</p>
            </div>
            <div className="col-md-6 position-relative">
              <div className="svg-img">
                <img src={aboutUs} loading="lazy" alt="Hosting Romania" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="packages-intro">
        <div className="packages-area pb-2 hosting-plans-intro container-fluid pt-100">
          <div className="container">
            <div className="section-title section-title-800">
              <h2 className="h3-title mb-3 text-white">Hosting WooCommerce intuitiv</h2>
              <p className="text-white">Ușor de folosit atât de către pasionați, cât și de către programatori, pachetele
                de hosting WooCommerce și găzduire WordPress se pot activa imediat
                ce ai finalizat comanda. </p>
              <p className="text-white">În câteva minute de la înregistrarea plății online vei primi email-ul de
                activare a contului.</p>
            </div>
          </div>
        </div>
      </section>
    </section>

  )
}

export default PromoInfoRowWordpress
